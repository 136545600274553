<template>
  <div id="search-categories">
    <!-- FILTROS -->
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>{{ $t('category.card') }}</span>
        </template>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectCategory"
          name="some-radios"
        >
          <b-row>
            <b-col
              v-for="category in categoriesResult"
              :id="category.node.id + ' ' + category.node.order"
              :key="category.node.id"
              class="mt-5"
              md="6"
              lg="4"
              xl="3"
              sm="12"
            >
              <b-card
                class="carCat"
                no-body
                @click.stop.prevent="!selectCat ? routeToView(category.node, 'view') : clickCheck(category.node)"
              >
                <b-img
                  :src="buildImageUrl(category)"
                  class="card-img-top cursor-pointer"
                  img-alt="Card image"
                  fluid-grow
                  @error="errorImg"
                />
                <b-card-header>
                  <div id="acortarPadre">
                    <b-card-title
                      id="acortar"
                      class="mb-25"
                    >
                      <span
                        v-if="category.node.state == 'GRE'"
                        class="mr-50 bullet bullet-success bullet-sm"
                      />
                      <span
                        v-if="category.node.state == 'YEW'"
                        class="mr-50 bullet bullet-warning bullet-sm"
                      />
                      <span
                        v-if="category.node.state == 'RED'"
                        class="mr-50 bullet bullet-danger bullet-sm"
                      />
                      <span
                        v-if="category.node.state == 'BCK'"
                        class="mr-50 bullet bullet-dark bullet-sm"
                      />

                      {{
                        category.node.name
                      }}
                    </b-card-title>
                    <b-card-text class="mb-0">
                      {{ $t(categoryTypes[category.node.type]) }}
                    </b-card-text>
                  </div>
                </b-card-header>

                <b-card-body>
                  <div
                    v-if="!selectCat"
                    class="actions"
                  >
                    <b-dropdown
                      :text="category.node.state == 'GRE' ? $t('Activada') : category.node.state == 'RED' ? $t('Desactivada') : $t('Interna')"
                      :variant="category.node.state == 'GRE' ? 'outline-success' : category.node.state == 'RED' ? 'outline-danger' : 'outline-warning'"
                      class="p-2"
                      size="sm"
                    >
                      <b-dropdown-item @click.stop.prevent="setIsActive(category.node, 'RED')">
                        🔴 {{ $t('Desactivada') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="setIsActive(category.node, 'YEW')">
                        🟡 {{ $t('Interna') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop.prevent="setIsActive(category.node, 'GRE')">
                        🟢 {{ $t('Activada') }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <div class="action">
                      <feather-icon
                        v-show="sizeWindow < 1200 || sizeWindow > 1630"
                        class="cursor-pointer"
                        icon="Edit2Icon"
                        size="20"
                        @click.stop.prevent="routeToView(category.node, 'edit')"
                      />

                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            color="white"
                            icon="MoreVerticalIcon"
                            size="20"
                          />
                        </template>
                        <b-dropdown-item
                          v-show="sizeWindow >= 1200 && sizeWindow <= 1630"
                          @click.stop.prevent="routeToView(category.node, 'edit')"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            icon="Edit2Icon"
                            size="18"
                          />
                          {{ $t("Edit") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="goToInit(category)"
                          @click.stop.prevent="updateParent(category)"
                        >
                          <feather-icon
                            icon="CornerRightUpIcon"
                            size="18"
                          />
                          {{ $t("contents.moveToInit") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="!isSponsor"
                          @click.stop.prevent="showModal('moveToCat', category.node)"
                        >
                          <feather-icon
                            icon="CornerRightUpIcon"
                            size="18"
                          />
                          {{ $t("contents.moveToCat") }}
                        </b-dropdown-item>

                        <b-dropdown-item @click.stop.prevent="deleteCategory(category.node.id)">
                          <feather-icon
                            icon="DeleteIcon"
                            size="18"
                          />
                          {{ $t("contents.dropCat") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div
                    v-else
                    class="actionsBar right"
                  >
                    <div class="action">
                      <b-form-checkbox
                        disabled
                        :value="category.node.id"
                      />
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <!-- </b-overlay> -->
        </b-form-checkbox-group>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          <span> {{ $t("List") }}</span>
        </template>
        <b-card>
          <b-table-simple
            ref="refContentListTable"
            show-empty
            hover
            selectable
            :select-mode="single"
            :empty-text="$t('emptyText')"
            responsive
            primary-key="id"
            :sticky-header="true"
            :no-border-collapse="true"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-for="i in tableColumns"
                  :key="i.key"
                >
                  {{ i.label }}
                </b-th>
              </b-tr>
            </b-thead>

            <b-tr
              v-for="item in categoriesResult"
              :key="item.node.id"
            >
              <b-td @click="routeToView(item.node)">
                <div class="d-flex align-items-center">
                  <b-img
                    id="avatar"
                    class="mr-2 ml-2"
                    rounded="lg"
                    :src="buildImageUrl(item)"
                    @error="errorImg"
                  />
                  <p>{{ item.node.name }}</p>
                </div>
              </b-td>

              <b-td @click="routeToView(item.node, 'view')">
                {{ item.node.isFinal ? 'Final' : 'No final' }}
              </b-td>
              <b-td @click="routeToView(item.node, 'view')">
                {{ $t(categoryTypes[item.node.type]) }}
              </b-td>
              <b-td>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      color="white"
                      icon="MoreVerticalIcon"
                      size="20"
                    />
                  </template>

                  <div>
                    <b-dropdown-item @click="routeToView(item.node, 'edit')">
                      <feather-icon
                        icon="Edit2Icon"
                        size="20"
                      />
                      {{ $t("contents.editCat") }}
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import configCategoryType from '@core/utils/categoryType'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  utf8ToB64, checkRelationOrder, removeRelation, showToast,
  isEmpty, specialCategory, messageError,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { mapGetters } from 'vuex'

import {
  BCol,
  BImg,
  BRow,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BTab,
  BTabs,
  BCardTitle,
  BFormCheckboxGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'
import axios from '@axios'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  name: 'Search',
  components: {
    BDropdown,
    BDropdownItem,
    BImg,
    BTab,
    BTabs,
    BCol,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BRow,
    BFormCheckboxGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    goToInit: Function,
    updateParent: Function,
    deleteCategory: Function,
    setIsActive: Function,
    showModal: Function,
    childCategory: {},
    selectCat: {
      type: Boolean,
      default: false,
    },
    allCat: {
      type: Boolean,
      default: false,
    },
    isFinal: {
      type: Boolean,
      default: null,
    },
    sponsor: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: null,
    },
    parent: {
      type: Boolean,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },
    orderBy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    searchById: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categoriesResult: [],
      tableColumns: [
        { key: 'name', label: this.$t('message.tableHeader.name') },
        { key: 'is_final', label: this.$t('contents.finalCategory') },
        { key: 'type', label: this.$t('contents.categoryType') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      categoryTypes: configCategoryType(true, false, false, false),
      specialCategory,
      show: false,
      todos: false,
      single: 'single',
      userData: getUserData(),
      headers: {},
      isSponsor: false,
      selectCategory: [],
      infoCategory: [],
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      isPod: false,
      mov: 0,
      changed: false,
    }
  },
  computed: {
    ...mapGetters('app', ['sizeWindow']),
  },
  watch: {
    allCat() {
      if (this.allCat) {
        this.categoriesResult.forEach(element => {
          this.selectCategory.push(element.node.id)
          this.infoCategory.push(element.node)
        })
      } else {
        this.selectCategory = []
        this.infoCategory = []
      }
      this.$emit('returnCat', [this.selectCategory, this.infoCategory])
    },
    isFinal() {
      this.getCategories()
    },
    search() {
      this.getCategories()
    },
    parent() {
      this.getCategories()
    },
    state() {
      this.getCategories()
    },
    type() {
      this.getCategories()
    },
    currentPage() {
      this.getCategories()
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getCategories()
  },

  methods: {

    getCategories() {
      const { headers } = this
      this.show = true
      const query = `query{
          allCategories(
            client:"${this.userData.profile.client.id}",
            ${!this.searchById ? 'name' : 'id'}:"${this.search}",
            first: ${this.perPage},
              offset: ${this.perPage * (this.currentPage - 1)},
              ${this.sponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}
            ${!isEmpty(this.parent) ? `parent_Isnull:${this.parent},` : ''}
            ${!isEmpty(this.state) ? `isActive:${this.state},` : ''}
            ${!isEmpty(this.isFinal) ? `isFinal:${this.isFinal},` : ''}
            ${!isEmpty(this.type) ? `type:"${this.type}",` : ''}
            ) {
            totalCount
            edges {
              node {
                id
                name
                orderType
                order
                image
                imageUrl
                mediaLocation
                isFinal
                isActive
                state
                type
                parent {
                  id
                  name
                  isFinal
                }
                categoryMenu {
                  totalCount                    
                }  
                categorySlide{
                  totalCount                    
                }  
              }
            }
          }
        }`
      axios
        .post('', {
          query,
        }, { headers }).then(result => {
          messageError(result, this)
          const res = result.data.data.allCategories
          const { totalCount } = res
          const { length } = res.edges
          this.$emit('pagCount', [totalCount, length])
          this.categoriesResult = res.edges
          this.show = false
        }).catch(err => {
          console.log(err)
          this.show = false
        })
    },
    deactivatedMenu() {
      const query = `{
        allMenu (category:"${utf8ToB64(`id:${this.catSelc}`)}"){
          edges {
            node {
              id
              isActive
              category {
                id
              }
            }
          }
        }
      }`
      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          const res = result.data.data.allMenu.edges
          let notMutation = 0
          if (res.length !== 0) {
            let query2 = 'mutation{'
            res.forEach((element, i) => {
              if (element.node.isActive) {
                query2 += `menu_${i + 1}: updateMenu(id:"${element.node.id}",input:{isActive:false}){
                  menu{
                    id
                    isActive
                  }
                }`
              } else {
                notMutation += 1
              }
            })
            query2 += '}'
            if (notMutation < res.length) {
              axios
                .post('', {
                  query: query2,
                }).then(re => {
                  messageError(re, this)

                  showToast(this.$t('deactivateCat'), 1, this)
                }).catch(err => {
                  console.log(err)
                })
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    getContents() {
      const { headers } = this
      this.show = true
      axios
        .post('', {
          variables: {
            search: this.search,
          },
          query: `
         query($search: String){
          allCategories(
          search:$search,
          ${this.search.length === 0 ? 'first:50,' : ''}
          orderBy:${this.orderBy === true ? '"-createdAt"' : '"createdAt"'},
          client:"${this.userData.profile.client.id}"
          ${this.sponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}
          ${this.state != null ? `,state : "${this.state}"` : ''}
          ){
          totalCount
          edges {
            node {
              id
              name
              reference
              imageUrl
              createdAt
              type
              modifiedAt
              emisionDate
              publishDate
              expirationDate
              mediaLocation
              isActive
              state
              contentContentOrder
              ${this.catSelc != null
    ? `(category:"${utf8ToB64(
      `category:${this.catSelc}`,
    )}")`
    : ''
}{
                  totalCount
                  edges {
                    node {
                      id
                      order
                      category {
                        id
                        name
                      }
                    }
                  }
                }
              categories ${this.catSelc != null ? `(id : "${this.catSelc}")` : ''
}{
                totalCount
                edges {
                  node {
                    id
                    name
                    isFinal
                  }
                }
              }

            }
          }
        }
      }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          // eslint-disable-next-line vue/no-mutating-props
          this.search = response.data.data.allContents.edges

          this.show = false
        }).catch(res => {
          console.log(res)
        })
    },
    catEspecial(cat) {
      this.$swal({
        title: this.$t('specialCategoryTitle'),
        text: this.$t('specialCategoryMsg', { category_name: cat.name, category_type: this.$t(this.categoryTypes[cat.type]) }),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },
    routeToContentView(folderId) {
      this.$router.push({ name: 'contents-edit', params: { id: folderId } })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    clickCheck(node) {
      const i = this.selectCategory.indexOf(node.id)

      if (i !== -1) {
        this.selectCategory.splice(i, 1)
        this.infoCategory.splice(i, 1)
      } else {
        this.selectCategory.push(node.id)
        this.infoCategory.push(node)
      }
      this.$emit('returnCat', [this.selectCategory, this.infoCategory])
    },
    moveToCategory(category) {
      this.categoryName = category.node.name
      this.categoryId = category.node.id
      this.hideModal()
      let query = `query
      {`
      for (let i = 0; i < this.selectCategory.length; i += 1) {
        query += `
        q${i + 1}: allContents(id:"${this.selectCategory[i]}"){
            edges {
              node {
                id
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          `
      }
      query += `
      }`

      axios
        .post('', { query }).then(res => {
          messageError(res, this)

          const deleteFilter = []
          let query2 = ` mutation
      {`
          for (let a = 0; a < this.selectCategory.length; a += 1) {
            const data = res.data.data[`q${a + 1}`].edges[0].node
            deleteFilter.push(data.id)

            const categoriasRes = []
            try {
              const categories = data.categories.edges.filter(
                element => element.node.id !== this.catSelc,
              )

              categories.forEach(item => categoriasRes.push(item.node.id))

              categoriasRes.push(this.categoryId)
            } catch (error) {
              console.log(error)

              categoriasRes.push(this.categoryId)
            }

            query2 += `
              m${a + 1}: updateContents(id:"${data.id}", input:{
              categories: [${categoriasRes}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }

                `
          }
          query2 += `
            }`
          axios
            .post('', { query: query2 }).then(result => {
              messageError(result, this)

              removeRelation(this.catSelc, this.selectCategory) // eliminamos relación anterior
              checkRelationOrder(this.categoryId).then(() => {
                this.getContents()
              }).catch(() => {

              }) // Creamos nueva relación
            }).catch(err => {
              console.log(err)
            })
        }).catch(err => {
          console.log(err)
        })
    },

    routeToView(node, type) {
      const { id } = node
      switch (type) {
        case 'edit':
          this.$router.push({ name: 'category-edit', params: { id } })

          break
        case 'view':
          if (this.specialCategory(node.type)) {
            this.catEspecial(node)
            // this.$router.push({ name: "category-edit", params: { id: id } });
          } else if (node.type === 'LIN') this.$router.push({ name: 'link-categories', params: { id, link: 'LIN' } })
          else this.$router.push({ name: 'contents-subcategories', params: { id } })

          break
        default:
          break
      }
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

  },
}
</script>

<style lang="scss" scoped>
#search-categories .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#search-categories .card-body {
  padding: 0rem;
}

#search-categories .card-body h4 {
  font-size: 1rem !important;
}

#search-categories .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

#search-categories .dropContainer {
  border: 3px dashed;
}

#search-categories .editIcon {
  color: darkcyan;
  margin-right: 10px;
}

#search-categories .deleteIcon {
  color: red;
}

#search-categories .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

#search-categories #button-content {
  padding: 0px;
}

#search-categories #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#search-categories #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#search-categories .carCat img {
  height: 11vw;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  #search-categories .carCat img {
    height: 35vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) {

  #search-categories .carCat img {
    height: 23vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1200px) {

  #search-categories .carCat img {
    height: 12vw;
    object-fit: cover;

  }
}

#search-categories .searchCategories {

  width: 130px;
  box-sizing: border-box;
  font-size: 18px;
  background-image: url('~@/assets/images/icons/searchIcon.png');
  background-position: 10px 8px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#search-categories .active {
  width: 100%;

}
</style>
